import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { InputAdornment, TextField } from '@mui/material';

const CampoNumero = ({ nombre, etiqueta, max = null, prefijo = false, requerido = false, touched, errors,  deshabilitado = null, ...props }) => {
  return (
    <Field
      as={TextField}
      fullWidth
      required={requerido}
      id={nombre}
      name={nombre}
      type="tel"
      label={etiqueta}
      autoComplete="off"
      {...props}
      disabled={deshabilitado}
      inputProps={{
        maxLength: max,
        inputMode: 'numeric',
        onKeyPress: (event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        },
      }}
      placeholder={etiqueta}
      InputProps={{
        ...(prefijo && {
          startAdornment: <InputAdornment position="start">{prefijo}</InputAdornment>,
        })
      }}
      error={(touched && touched[nombre] && errors && errors[nombre]) ? true : false}
      helperText={(touched && touched[nombre] && errors && errors[nombre]) && (
        <ErrorMessage name={nombre} />
      )}
    />
  );
};

export default CampoNumero;
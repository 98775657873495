import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { esES } from '@mui/material/locale';
import { AlertaProvider } from 'context/AlertaContext'
import { DialogoProvider } from 'context/DialogoContext'
import PerfilContext from 'context/PerfilContext'
import React, { useContext } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { esES } from '@mui/x-data-grid/locales';

export default function OtrosProvider(props) {
  const queryClient = new QueryClient()
  const { tema, diseno } = useContext(PerfilContext)

  // Define los colores para light mode
  const lightPalette = {
    primary: {
      main: diseno?.light_primary_main || '#1e5d8d',
      light: diseno?.light_primary_light || '#1E5D8D6E',
      dark: diseno?.light_primary_dark || '#06113c'
    },
    secondary: {
      main: diseno?.light_secondary_main || '#e5e5e5',
      light: diseno?.light_secondary_light || '#06113c',
      dark: diseno?.light_secondary_dark || '#1E5D8D6E'
    }
  };

  // Define los colores para dark mode
  const darkPalette = {
    primary: {
      main: diseno?.dark_primary_main || '#1e5d8d',
      light: diseno?.dark_primary_light || '#1E5D8D6E',
      dark: diseno?.dark_primary_dark || '#06113c'
    },
    secondary: {
      main: diseno?.dark_secondary_main || '#a6a6a6',
      light: diseno?.dark_secondary_light || '#1E5D8D6E',
      dark: diseno?.dark_secondary_dark || '#1e5d8d'
    }
  };

  const palette = tema === 'dark' ? darkPalette : lightPalette;

  const theme = createTheme(
    {
      palette: {
        mode: tema,
        ...palette
      },
      typography: {
        fontFamily: [
          diseno?.font_family || 'Roboto,Arial,sans-serif',
        ].join(','),
        allVariants: {
          fontWeight: '500',
        }
      },
    },
    esES,
  )

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <DialogoProvider>
          <AlertaProvider>
            {props.children}
          </AlertaProvider>
        </DialogoProvider>
      </QueryClientProvider>
    </ThemeProvider >
  )
}

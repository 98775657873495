import React, { Suspense } from 'react'
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom"
import { MenuProvider } from "./context/MenuContext";
import { DataProvider } from 'context/DataContext';
import RequireAuth from '@auth-kit/react-router/RequireAuth'
import rutasConfig from './RutasConfig';
import { Loading } from 'components/Loading';
import Menu from 'components/Menu'
import Login from 'pages/login/Login'
import Registro from 'pages/registro/Registro'
// import { ReactQueryDevtools } from 'react-query/devtools'

export default function Rutas() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path={'login'} element={<Login />} />
          <Route path={'register'} element={<Registro />} />
          <Route path="/"
            element={
              <RequireAuth fallbackPath='login'>
                <DataProvider url={`${process.env.REACT_APP_URL}/menu`} modulo='menu' filtrosBusqueda={['nombreModulo']}>
                  <MenuProvider>
                    <Menu />
                  </MenuProvider>
                </DataProvider>
                <Outlet />
              </RequireAuth>
            }
          >
            {rutasConfig.map((ruta) => (
              <Route key={ruta.path} path={ruta.path} element={<RequireAuth fallbackPath="login">{ruta.element}</RequireAuth>} />
            ))}
          </Route>
          <Route path="*" element={<Navigate replace to="login" />} />
        </Routes>
        {/* <ReactQueryDevtools /> */}
      </Suspense>
    </BrowserRouter>
  )
}
import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

function Agradecimiento() {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid 
      container 
      sx={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}
    >
      <Grid 
        item 
        xs={12} 
        md={6} 
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <img
          src="/assets/imgs/cierre_registro.jpg"
          alt="Agradecimiento"
          style={{
            width: isMdUp ? 'auto' : '100%',
            height: isMdUp ? 'auto' : '100vh',
            maxWidth: isMdUp ? '500px' : '100%',
            maxHeight: '100vh',
            objectFit: isMdUp ? 'cover' : 'fill',
          }}
        />
      </Grid>
    </Grid>
  );
}

export default Agradecimiento;

import React, { useContext, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, Box, TextField, Button, Paper, Typography, useTheme } from '@mui/material';
import CampoTexto from 'components/CampoTexto';
import CampoNumero from 'components/CampoNumero';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import 'moment/locale/es';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { desencriptar, encriptar } from 'utilities/encriptado';
import DialogoContext from 'context/DialogoContext';
import AlertaContext from 'context/AlertaContext';
import Agradecimiento from 'components/Agradecimiento';

moment.locale('es');

export default function Registro(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { handleSetOpenDialogo, setEnvioFormulario } = useContext(DialogoContext);
  const { handleOpenAlerta } = useContext(AlertaContext);
  const [registroExitoso, setRegistroExitoso] = useState(false);

  const mutation = useMutation(async ({ data, resetForm }) => {
    const dataBody = encriptar(data)
    const response = await axios.post(`${process.env.REACT_APP_URL}/clientes/register`, { dataBody });
    return { data: response.data, resetForm };
  },
    {
      retry: 3,
      onSuccess: function ({ data, resetForm }) {
        const respuesta = desencriptar(data);

        if (!respuesta.error) {
          setEnvioFormulario(false);
          handleOpenAlerta('success', respuesta.message);
          handleSetOpenDialogo(false);
          queryClient.invalidateQueries(props.modulo);
          resetForm();
          setRegistroExitoso(true); // Muestra el componente de agradecimiento

        } else {
          setEnvioFormulario(false);
          handleOpenAlerta('error', respuesta.message);
          console.log("error");
        }
      },
      onError: function (error) {
        setEnvioFormulario(false);
        handleOpenAlerta('error', t('generico.error'));
        console.log(error);
      }
    });

  const renderHead = (
    <Box sx={{ width: '100%' }}>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Box component="img" sx={{ maxWidth: '240px' }} alt="logo" src='/assets/imgs/logo.png' />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', marginTop: 2 }}>{t('registro.formulario')}</Typography>
      </Grid>
    </Box>
  );

  const valoresIniciales = {
    primer_nombre: '',
    primer_apellido: '',
    codigo_pais: '',
    telefono_movil: '',
    correo_principal: '',
    fecha_nacimiento: ''
  };

  const validarFormulario = (valores) => {
    let errores = {};

    if (!valores.primer_nombre) {
      errores.primer_nombre = t('personas.errores.primer_nombre');
    }

    if (!valores.primer_apellido) {
      errores.primer_apellido = t('personas.errores.primer_apellido');
    }

    if (!valores.correo_principal) {
      errores.correo_principal = t('personas.errores.correo_principal');
    }

    let campoEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (valores.correo_principal && !campoEmail.test(valores.correo_principal)) {
      errores.correo_principal = t('personas.errores.correo_invalido');
    }

    if (!valores.codigo_pais) {
      errores.codigo_pais = t('personas.errores.codigo_pais')
    }

    if (!valores.telefono_movil) {
      errores.telefono_movil = t('personas.errores.telefono_movil');
    }

    if (!valores.fecha_nacimiento) {
      errores.fecha_nacimiento = t('personas.errores.fecha_nacimiento');
    }

    if (valores.fecha_nacimiento) {
      const fechaNacimiento = moment(valores.fecha_nacimiento, 'DD/MM/YYYY');
      const edad = moment().diff(fechaNacimiento, 'years');
      if (edad <= 18) {
        errores.fecha_nacimiento = t('personas.errores.mayor_18');
      }
    }

    return errores;
  };

  const enviarFormulario = (valores, { resetForm }) => {
    var data = {
      tipo_persona: 1,
      primer_nombre: valores.primer_nombre,
      primer_apellido: valores.primer_apellido,
      codigo_pais: valores.codigo_pais,
      telefono_movil: valores.telefono_movil,
      correo_principal: valores.correo_principal,
      fecha_nacimiento: valores.fecha_nacimiento
    };

    mutation.mutate({ data, resetForm });
  };

  const renderForm = (
    <Formik
      initialValues={valoresIniciales}
      validate={(valores) => validarFormulario(valores)}
      onSubmit={enviarFormulario}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Grid container>
          <Grid item xs={0} lg={8} md={8} sx={{ display: { xs: 'none', md: 'block' }, backgroundImage: 'url(/assets/imgs/Blamekiki.jpg)', backgroundSize: 'cover', backgroundPosition: 'center', minHeight: '100vh' }}>
            <Box sx={{ paddingTop: '10vh', display: 'inline-block' }}>
              <Paper elevation={5} sx={{ p: 2, backgroundColor: '#1E5D8D6E' }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'white' }}>{t('registro.bienvenida')}</Typography>
                <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'white' }}>{t('registro.titulo')}</Typography>
                <Typography variant="body1" sx={{ color: 'white', mt: 0.5 }}>{t('registro.descripcion')}</Typography>
              </Paper>
            </Box>
          </Grid>

          <Grid item xs={12} lg={4} md={4}>
            <Paper elevation={5} sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', pl: 3, pr: 3 }}>
              <Form className="formulario" >
                <Grid container spacing={1}>
                  {renderHead}
                  <Grid item xs={12}>
                    <CampoTexto nombre="primer_nombre" etiqueta={t('personas.nombre')} requerido={true} errors={errors} touched={touched} />
                  </Grid>
                  <Grid item xs={12}>
                    <CampoTexto nombre="primer_apellido" etiqueta={t('personas.apellido')} requerido={true} errors={errors} touched={touched} />
                  </Grid>

                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterMoment} locale="es">
                      <Field
                        id="fecha_nacimiento"
                        name="fecha_nacimiento"
                        label={t(`personas.fecha_nacimiento`) + ' *'}
                        component={DatePicker}
                        className="dateTimeField"
                        slotProps={{
                          textField: {
                            helperText: errors.fecha_nacimiento ? errors.fecha_nacimiento : '',
                          },
                        }}
                        value={values.fecha_nacimiento ? moment(values.fecha_nacimiento, 'DD/MM/YYYY') : null}
                        onChange={(newValue) => setFieldValue("fecha_nacimiento", newValue ? newValue.format('DD/MM/YYYY') : null)}
                        inputFormat="DD/MM/YYYY"
                        autoComplete="off"
                        required={true}
                        sx={{
                          '& .MuiFormHelperText-root': { color: theme.palette.error.light },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={3}>
                    <CampoNumero nombre='codigo_pais' etiqueta={t('personas.codigo_pais')} requerido={true} errors={errors} touched={touched} />
                  </Grid>

                  <Grid item xs={9}>
                    <CampoNumero nombre='telefono_movil' etiqueta={t('personas.telefono_movil')} requerido={true} errors={errors} touched={touched} />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="correo_principal"
                      type="email"
                      label={t('personas.correo_principal')}
                      as={TextField}
                      autoComplete="off"
                      placeholder={t('personas.correo_principal')}
                      error={(touched.correo_principal && errors.correo_principal) ? true : false}
                      helperText={<ErrorMessage name="correo_principal" component={() => (errors.correo_principal)} />}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Button
                      type='submit'
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={(Object.keys(errors).length > 0) ? true : false}
                      sx={{ width: '50%', textTransform: 'none', borderRadius: 5 }}
                    >
                      {t('boton.registrar')}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Formik>
  );

  return (
    <>
      {registroExitoso ? <Agradecimiento /> : renderForm}
    </>
  );
}
